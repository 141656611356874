import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { useAuth0 } from '../../contexts/auth0-context';
import { AVAILABLE_CURRENCIES, getPortalInfo, getUserDetails } from '../../shared/utils';
import { setCurrency } from '../../store/actions/uiActions';
import PartnerBadge from '../PartnerBadge/PartnerBadge';
import './Header.module.scss';
import classes from './Header.module.scss';

export default function Header() {
    const { isLoading, user, loginWithRedirect, logout } = useAuth0();
    const currency = useSelector(state => state.ui.currency);
    const dispatch = useDispatch();

    useEffect(() => {
        window.localStorage.setItem('currency', currency);
    }, [ currency ]);

    const handleCurrencyChange = (e) => {
        dispatch(setCurrency(e.target.value));
    };

    const getCurrencySelect = () => {
        return <select onChange={handleCurrencyChange} value={currency}>
            {Object.keys(AVAILABLE_CURRENCIES).sort().map(id => <option key={id}
                                                                        value={id}>{AVAILABLE_CURRENCIES[ id ].label}</option>)}
        </select>;
    };

    return (
        <header>
            <nav className={clsx('navbar has-shadow is-spaced is-fixed-top', classes.Nav)}>
                <div className="navbar-start">
                    <div className="navbar-brand">
                        <Link to="/" className={clsx('navbar-item', classes.Logo)}>
                            <img alt="Company logo" src={getPortalInfo().headerLogo} />
                        </Link>
                    </div>

                    <div className="navbar-item" />

                    <NavLink activeClassName="is-active" to="/promotions" className="navbar-item">
                        Promotions
                    </NavLink>

                    <div className="navbar-item has-dropdown is-hoverable">
                        <NavLink activeClassName="is-active" to="/presentation/aida-en" className="navbar-link">
                            Presentations
                        </NavLink>
                        <div className="navbar-dropdown">
                            <NavLink activeClassName="is-active" to="/presentation/aida-en" className="navbar-item">
                                AIDA (EN - public)
                            </NavLink>
                            <NavLink activeClassName="is-active" to="/presentation/aida-en-partners"
                                     className="navbar-item">
                                AIDA (EN - partners only)
                            </NavLink>
                            <NavLink activeClassName="is-active" to="/presentation/aida-it" className="navbar-item">
                                AIDA (IT)
                            </NavLink>
                            <NavLink activeClassName="is-active" to="/presentation/aida-es" className="navbar-item">
                                AIDA (ES)
                            </NavLink>
                            <hr className="navbar-divider" />
                            <NavLink activeClassName="is-active" to="/presentation/aida-ds-bundle-en"
                                     className="navbar-item">
                                AIDA/DocuShare bundle (EN)
                            </NavLink>
                            <NavLink activeClassName="is-active" to="/presentation/aida-ds-bundle-it"
                                     className="navbar-item">
                                AIDA/DocuShare bundle (IT)
                            </NavLink>
                            <hr className="navbar-divider" />
                            <NavLink activeClassName="is-active" to="/presentation/aida-legal-en"
                                     className="navbar-item">
                                AIDA/DocuShare for legal firms (EN)
                            </NavLink>
                            <NavLink activeClassName="is-active" to="/presentation/aida-legal-it"
                                     className="navbar-item">
                                AIDA/DocuShare per studio legale (IT)
                            </NavLink>
                            <hr className="navbar-divider" />
                            <NavLink activeClassName="is-active" to="/presentation/aida-hr-en"
                                     className="navbar-item">
                                AIDA/DocuShare for Human Resources management (EN)
                            </NavLink>
                            <NavLink activeClassName="is-active" to="/presentation/aida-hr-it"
                                     className="navbar-item">
                                AIDA/DocuShare per gestione HR (IT)
                            </NavLink>
                            <hr className="navbar-divider" />
                            <NavLink activeClassName="is-active" to="/presentation/aida-xerox-delivery-services-it"
                                     className="navbar-item">
                                AIDA/Xerox Delivery Services (IT)
                            </NavLink>
                            <NavLink activeClassName="is-active" to="/presentation/aida-xerox-delivery-services-en"
                                     className="navbar-item">
                                AIDA/Xerox Delivery Services (EN)
                            </NavLink>
                            <hr className="navbar-divider" />
                            <NavLink activeClassName="is-active" to="/presentation/aida-security-en"
                                     className="navbar-item">
                                AIDA security and certifications (EN)
                            </NavLink>
                            <NavLink activeClassName="is-active" to="/presentation/aida-security-it"
                                     className="navbar-item">
                                AIDA sicurezza e certificazioni (IT)
                            </NavLink>
                            <hr className="navbar-divider" />
                            <NavLink activeClassName="is-active" to="/presentation/aida-brochure-en"
                                     className="navbar-item">
                                AIDA brochure (EN)
                            </NavLink>
                        </div>
                    </div>

                    <NavLink activeClassName="is-active" to="/order" className="navbar-item">
                        Order form
                    </NavLink>

                    <NavLink activeClassName="is-active" to="/customers" className="navbar-item">
                        Customers
                    </NavLink>

                    <div className="navbar-item has-dropdown is-hoverable">
                        <span className="navbar-link">
                            Support
                        </span>
                        <div className="navbar-dropdown">
                            <NavLink activeClassName="is-active" to="/renewals" className="navbar-item">
                                Contract renewals/upgrades
                            </NavLink>
                            <a href="https://doc.aidacloud.com" className="navbar-item" target="_blank"
                               rel="noopener noreferrer">
                                AIDA Docs
                            </a>
                            <a href="https://www.aidacloud.com/en/support/contacts" className="navbar-item"
                               target="_blank"
                               rel="noopener noreferrer">
                                Contact form
                            </a>
                        </div>
                    </div>
                </div>

                <div className="navbar-end">
                    <div className="field-body navbar-item">
                        <div className="control">
                            <div className="select is-small">
                                {getCurrencySelect()}
                            </div>
                        </div>
                    </div>

                    {!isLoading && !user && (
                        <button onClick={loginWithRedirect} className="navbar-item">
                            Login
                        </button>
                    )}

                    {!isLoading && user && (
                        <>
                            <span className="navbar-item is-size-7" style={{ marginRight: '12px' }}>
                                <span style={{ marginRight: '20px' }}>{user.name}</span>
                                <PartnerBadge level={getUserDetails(user).level} />
                            </span>
                            <button
                                onClick={() => logout({ returnTo: window.location.origin })}
                                className="button is-small"
                            >
                                Logout
                            </button>
                        </>
                    )}
                </div>
            </nav>
        </header>
    );
}
